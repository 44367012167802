import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`A switch toggles the state of a setting (between on and off). Switches and checkboxes can often be used interchangeably, but the switch provides a more explicit, visible representation on a setting.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-1.svg",
      "alt": "switch anatomy guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Track :`}</strong>{` the track is an element used to guide the Thumb between alternating states.`}</li>
      <li parentName="ol"><strong parentName="li">{`Thumb :`}</strong>{` the Thumb is a circular element on the switch that the user interacts with to turn something on or off.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Switch are very flexible components that can be placed anywhere you need to provide a user with an on and off option if used correctly.`}</p>
    <p><strong parentName="p">{`Common places switch appear:`}</strong>{` Forms, settings pages, Modals etc`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To enable users to change a single setting.</li>
      <li> When the result of turning the setting on and off is clear. </li>
      <li> For changes that have an instant response. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> To present multiple options. </li>
      <li> For settings that require additional help or error handling. </li>
      <li> For changes that require an additional confirmation. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`All switch types display their state (on or off) through different methods and locations. It should be visible at a glance if a switch has been selected, and selected items should be more visually prominent than unselected items.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-4.svg",
      "alt": "accessibility"
    }}></img>
    <p>{`Always maintain a minimum 20 pixel margin between Switch or components so that they do not become lost.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : On & off item option" caption="Use Switches to provide multiple on and off options for a set of items" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Multiple item option" caption="Use Radios to provide on and off functionality for a set of items" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating switch :`}</p>
    <ul>
      <li parentName="ul">{`Use a basic switch by default unless space is limited, then use a switch without a label.`}</li>
      <li parentName="ul">{`Add a form label in front of your switch to specify what setting a switch turns on or off, such as Wi-fi or Bluetooth.`}</li>
      <li parentName="ul">{`If you’re using a basic switch, add switch labels to clarify between toggled settings, such as On and Off.`}</li>
      <li parentName="ul">{`Don’t include the state of a switch in a label if you’re using a switch with a label and a checkmark.`}</li>
      <li parentName="ul">{`Don’t use a switch if the options you’re presenting to the user are anything other than “on” / “off.” Instead, use radio buttons.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Spesific context" caption="Use a switch for situations where you are turning a series of one or more independent options on or off" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Long context item" caption="Use checkbox for situation where you can turning a series of one or more independent options on or off" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch-6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      